import { Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export default function Loading({ loadingMessage }) {
  return (
    <Row
      className="error text-center"
      align="middle"
      justify="center"
      style={{ height: "100%" }}
    >
      <Col span={24}>
        <LoadingOutlined
          style={{ fontSize: "42px", color: "var(--et-color-1)" }}
        />
        <br />
        <p
          className="m-0 mt-3"
          style={{ color: "var(--et-color-1)", fontWeight: "bold" }}
        >
          {loadingMessage}
        </p>
      </Col>
    </Row>
  );
}
