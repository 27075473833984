import { useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import footerLogo from "../assets/images/eliteTrans_icon_no-bg.png";
import { Modal, Button, Form } from "react-bootstrap";
// import { useForm } from "@formspree/react";

export default function Footer() {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row footer-top pt-4 d-flex h-100 justify-content-center">
            <div className="col-md-3 text-lg-start text-center mb-2">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    className="img-fluid"
                    width={100}
                    alt="eliteTrans"
                    src={footerLogo}
                  />
                </Link>
              </div>
              <div className="footer-tagline">
                <p>On time, all the time</p>
              </div>
              <div className="footer-contact">
                <ul>
                  <li>
                    <div className="inner">
                      <p>
                        <i className="fas fa-envelope"></i>&nbsp;
                        <a href="mailto:info@elitetrans.us">
                          info@elitetrans.us
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <p>
                        <i className="fas fa-envelope"></i>&nbsp;
                        <a href="mailto:elitetransportandshipping@gmail.com">
                          elitetransportandshipping@gmail.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <p>
                        <i className="fas fa-phone"></i>&nbsp;
                        <a href="tel:+17734414682">+1 (773) 441 4682</a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <p>
                        <i className="fas fa-location-dot"></i>&nbsp;404 Ballard
                        drive Algonquin IL, 60102, USA.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-6 col-md-3 mb-4">
              <h4>Quick Links</h4>
              <ul className="footer-links">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-3 mb-4">
              <h4>Services</h4>
              <ul className="footer-links">
                <li>
                  <Link to="/ambulatory">Ambulatory Service</Link>
                </li>
                <li>
                  <Link to="/specialized">Specialized Care Service</Link>
                </li>
                <li>
                  <Link to="/sale-rental">Vehicle Sale/Rental Service</Link>
                </li>
              </ul>
            </div>

            <div className="col-md-3">
              <div className="footer-quote text-center">
                <h3 style={{ fontSize: "20px" }}>We can help, today.</h3>
                <p>24 hours a day, 7 days a week support.</p>
                <Button className="btn btn-primary et-btn" onClick={handleShow}>
                  Get A Free Quote
                </Button>
              </div>
            </div>
          </div>
          <div className="row flex text-center footer-bottom">
            <div className="copyright d-flex h-100 justify-content-center align-items-center col-12 col-md-6">
              <p>
                Copyright&nbsp;&copy;&nbsp;2023&nbsp;
                <Link
                  style={{
                    fontWeight: "bold",
                    color: "var(--et-color-1)",
                    textDecoration: "none",
                  }}
                  to="/"
                >
                  eliteTrans
                </Link>
                .&nbsp;All Rights Reserved.
              </p>
            </div>
            <div className="social-links col-12 col-md-6">
              <ul className="p-0 mb-0 footer-social-links">
                <li>
                  <a
                    href="https://www.instagram.com/elitetransportandshipping"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.x.com/eliteafricans"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://wa.me/+17734414682"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-whatsapp"></i>
                  </a>
                </li>
              </ul>
            </div>
            {/* <div className="developer-stamp">
              <div className="row">
                <div className="col-12">
                  <i className="fas fa-laptop-code"></i>
                  &nbsp;
                  <spam className="0xOneBeing">0xOneBeing</spam>
                </div>
                <div className="col-12">
                  <ul className="p-0 m-0">
                    <li>
                      <a
                        href="https://www.github.com/0xOneBeing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa-brands fa-github"
                          style={{ color: "#0d1117" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/in/0xOneBeing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa-brands fa-linkedin"
                          style={{ color: "#0a66c2" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.x.com/0xOneBeing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa-brands fa-x-twitter"
                          style={{ color: "#000000" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/@0xOneBeing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa-brands fa-youtube"
                          style={{ color: "#fe0000" }}
                        ></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://t.me/OxOneBeing"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <i
                          className="fa-brands fa-telegram"
                          style={{ color: "#30a3e6" }}
                        ></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </footer>

      <Modal show={showModal} onHide={handleClose} centered>
        <div style={{ backgroundColor: "#fff" }}>
          <Modal.Header closeButton closeLabel="Close form" closeVariant="redf">
            <Modal.Title className="text-center">Get A Free Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Floating className="mb-3" controlId="name">
                <Form.Control id="name" type="text" placeholder="Name" />
                <label htmlFor="name">Name</label>
              </Form.Floating>

              <Form.Floating className="mb-3" controlId="email">
                <Form.Control
                  id="email"
                  type="email"
                  placeholder="Email address"
                />
                <label htmlFor="email">Email address</label>
              </Form.Floating>

              <Form.Floating className="mb-3" controlId="phoneNo">
                <Form.Control id="phoneNo" type="text" placeholder="Phone" />
                <label htmlFor="phoneNo">Phone</label>
              </Form.Floating>

              <Form.Floating className="mb-3" controlId="message">
                <Form.Control
                  id="message"
                  as="textarea"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                />
                <label htmlFor="message">Message</label>
              </Form.Floating>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button
              style={{ backgroundColor: "var(--et-color=1)" }}
              onClick={handleClose}
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}
