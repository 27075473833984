import "../App.css";
import { Helmet } from "react-helmet";
import ServicesSidebar from "./ServicesSidebar";
import FAQ from "./FAQ";

import sienna6 from "../assets/images/sienna6.jpg";
import sienna_boot1 from "../assets/images/sienna_boot1.jpg";

export default function AmbulatoryService() {
  return (
    <section className="ambulatory">
      <Helmet>
        <title>Ambulatory Service : eliteTrans</title>
      </Helmet>
      <div
        className="about-bg py-5"
        style={{
          backgroundImage:
            "linear-gradient(45deg, var(--et-color-1), var(--et-color-2))",
          color: "whitesmoke",
        }}
      >
        <h1 className="text-center py-5 my-5">Ambulatory Service</h1>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9 order-md-2">
            <div className="img">
              <img
                className="mx-auto w-100 text-center img-fluid"
                alt="Service"
                src={sienna6}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-1-img1.jpg"
              />
            </div>
            <div className="mt-5">
              <h1 className="text-center">Ambulatory Service</h1>
              <p className="text-justify">
                Our Ambulatory Services provide essential non-emergency medical
                transportation and support, ensuring that individuals can access
                healthcare appointments and treatments with ease. We understand
                the importance of timely and safe transportation for medical
                needs, which is why our dedicated professionals offer
                personalized assistance to individuals who require help in
                moving to and from medical destinations. Whether it's a routine
                check-up, therapy session, or any other medical visit, our
                Ambulatory Services are designed to provide comfort,
                reliability, and peace of mind. With a focus on maintaining high
                safety standards and a commitment to personalized care, we're
                here to empower mobility and ensure that individuals can access
                the healthcare services they need.{" "}
              </p>
              {/* <p className="text-justify">
                t has survived not only five centuries. Lorem Ipsum is simply
                dummy text of the new design printng and type setting Ipsum take
                a look at our round. When an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting.{" "}
              </p> */}
            </div>
            <div className="row my-5">
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Help us Save a
                  Life
                </h4>
                <p>
                  Be a Lifesaver: Join or Engage in{" "}
                  <em>"Our Mission to Save Lives"</em>
                </p>
              </div>
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Join our Big
                  Family
                </h4>
                <p>
                  Welcome to Our Warm and Inclusive Community – Join Our Big
                  Family Today!
                </p>
              </div>
            </div>
            <p className="text-justify">
              Experience the convenience and care of our Ambulatory Services,
              dedicated to making healthcare journeys as smooth and stress-free
              as possible.
            </p>

            <div className="service-benefits my-5">
              <div className="row">
                <div className="col-md-6">
                  <h2>Service Benefits</h2>
                  <h4>Key Features of Our Ambulatory Services include:</h4>
                  <ul className="et-option-list p-0">
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Safe and
                      Reliable
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Personalized
                      Care
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Timely
                      Transportation
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Flexible
                      Scheduling
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Comprehensive
                      Support
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Accessible
                      Vehicles
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-center">
                  <img
                    className="img-fluid h-100"
                    alt="Care"
                    src={sienna_boot1}
                    // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-img2.jpg"
                  />
                </div>
              </div>
              <FAQ />
            </div>
          </div>
          <div className="col-md-3 order-md-1">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}
