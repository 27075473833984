import { Link } from "react-router-dom";
import "../App.css";
import "animate.css";

import img from "../assets/images/sienna_boot1.jpg";

export default function Trusted() {
  return (
    <div className="trusted p-5 m-0" style={{ backgroundColor: "#ee296a" }}>
      <div className="container">
        <div className="row align-items-center text-center">
          <div className="col-md-4">
            <img
              className="img-fluid animate__fadeInLeft animate__pulse"
              style={{
                width: "250px",
                filter: "brightness(0.8) contrast(1.2) saturate(1.5)",
              }}
              src={img}
              // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/ambulance.png"
              alt="Awesome"
            />
          </div>

          <div className="col-md-4">
            <h3
              style={{
                // color: "maroon",
                color: "var(--et-trans-1)",
              }}
            >
              Find out Why Our Services
              <br /> Are Trusted
            </h3>
            <p style={{ color: "maroon", fontWeight: "700" }}>
              Call anytime:&nbsp;
              <a
                style={{ color: "whitesmoke", textDecoration: "none" }}
                href="tel:+17734414682"
              >
                +1 (773) 441 4682
              </a>
            </p>
          </div>

          <div className="col-md-4">
            <Link className="btn et-btn" to="/contact">
              Contact Us&nbsp;<span className="fas fa-arrow-right"></span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
