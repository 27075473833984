import "../App.css";
import { Helmet } from "react-helmet";
import ServicesSidebar from "./ServicesSidebar";
import FAQ from "./FAQ";

import highlander3 from "../assets/images/highlander3.jpg";
import sienna_boot1 from "../assets/images/sienna_boot1.jpg";

export default function SpecializedService() {
  return (
    <section className="specialized">
      <Helmet>
        <title>Specialized Care Service : eliteTrans</title>
      </Helmet>
      <div
        className="about-bg py-5"
        style={{
          backgroundImage:
            "linear-gradient(45deg, var(--et-color-1), var(--et-color-2))",
          color: "whitesmoke",
        }}
      >
        <h1 className="text-center py-5 my-5">Specialized Care Service</h1>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9 order-md-2">
            <div className="img">
              <img
                className="mx-auto w-100 text-center img-fluid"
                alt="Service"
                src={highlander3}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-1-img1.jpg"
              />
            </div>
            <div className="mt-5 text-justify">
              <h1 className="text-center">Specialized Care Service</h1>
              <p className="text-justify">
                Our Specialized Care Services go beyond the ordinary, offering
                personalized care and support for individuals with unique
                medical and mobility requirements. We recognize that each
                person's situation is distinct, which is why our team of
                experienced professionals creates individualized care plans to
                address specific needs. From individuals requiring advanced
                medical equipment to those with specialized mobility challenges,
                our services are designed to ensure comfort, dignity, and
                well-being. With a focus on compassion and expertise, we're
                committed to enhancing the quality of life for those who need
                specialized attention.{" "}
              </p>
              {/* <p className="text-justify">
                t has survived not only five centuries. Lorem Ipsum is simply
                dummy text of the new design printng and type setting Ipsum take
                a look at our round. When an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting.{" "}
              </p> */}
            </div>
            <div className="row my-5">
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Help us Save a
                  Life
                </h4>
                <p>
                  Be a Lifesaver: Join or Engage in{" "}
                  <em>"Our Mission to Save Lives"</em>
                </p>
              </div>
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Join our Big
                  Family
                </h4>
                <p>
                  Welcome to Our Warm and Inclusive Community – Join Our Big
                  Family Today!
                </p>
              </div>
            </div>
            <p className="text-justify">
              Experience the difference of our Specialized Care Services, where
              compassion meets expertise to provide comprehensive and tailored
              support for individuals with diverse medical needs.
            </p>

            <div className="service-benefits my-5">
              <div className="row">
                <div className="col-md-6">
                  <h2>Service Benefits</h2>
                  <h4>
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum.
                  </h4>
                  <ul className="et-option-list p-0">
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Personalized
                      Care Plans
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Expert
                      Caregivers
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Dignity and
                      Comfort
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Assistance
                      with Medical Equipment
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Comprehensive
                      Support
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Flexible
                      Services
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-center">
                  <img
                    className="img-fluid h-100"
                    alt="Care"
                    src={sienna_boot1}
                    // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-img2.jpg"
                  />
                </div>
              </div>
              <FAQ />
            </div>
          </div>
          <div className="col-md-3 order-md-1">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}
