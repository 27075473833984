import { Row, Col } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export default function Error({ errorMessage }) {
  return (
    <Row
      className="error text-center"
      align="middle"
      justify="center"
      style={{ height: "100%" }}
    >
      <Col span={24}>
        <CloseOutlined
          style={{ fontSize: "42px", fontWeight: "bold", color: "red" }}
        />
        <br />
        <p className="m-0 mt-3" style={{ color: "red", fontWeight: "bold" }}>
          {errorMessage.toUpperCase()}
        </p>
      </Col>
    </Row>
  );
}
