import { Link } from "react-router-dom";
import "../App.css";

import eliteTrans_icon from "../assets/images/eliteTrans_icon_bg.png";

export default function MedicalServic() {
  return (
    <div className="medical-service">
      <div className="row">
        <div className="col-12 col-md-6 mb-3">
          <div className="img-text text-center">
            <div className="img" style={{ position: "relative" }}>
              <img
                className="img-fluid"
                src={eliteTrans_icon}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/about-style1__image-1.jpg"
                alt="Staff"
              />
              {/* <div className="info-text">
                  <span>
                    <i
                      style={{
                        color: "var(--et-color-1)",
                        fontSize: "72px",
                        position: "absolute",
                        bottom: "50"
                      }}
                      className="fas fa-bus"
                    ></i>
                    &nbsp;1987
                  </span>
                </div> */}
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 mb-3 d-flex align-items-center">
          <div className="">
            <div className="sec-title">
              <div className="icon" style={{ fontSize: "72px" }}>
                <span className="fas fa-heartbeat"></span>
              </div>

              <div className="sub-title">
                <small>Our Introductions</small>
              </div>
              <h2>A Leading Medical Service Provider</h2>
            </div>

            <div className="inner-content py-4">
              <div className="text-justify">
                <p>
                  eliteTrans team are set of capable, resourceful, and
                  problem-solving team, always happy when helping our clients
                  with whatever concierge and transportation challenge comes
                  their way. We assist busy executives, couples with two incomes
                  but no free time, individuals, and seniors by doing what their
                  clients are too busy or unable to do themselves.
                </p>
              </div>

              <div className="row mt-4">
                <div className="col-xl-6">
                  <div className="single-text-box">
                    <h3>
                      <span className="fas fa-arrow-right"></span>&nbsp;Help us
                      Save a Life
                    </h3>
                    <p>
                      Be a Lifesaver: Join or Engage in "Our Mission to Save
                      Lives"
                    </p>
                  </div>
                </div>

                <div className="col-xl-6">
                  <div className="single-text-box">
                    <h3>
                      <span className="fas fa-arrow-right"></span>&nbsp;Join our
                      Big Family
                    </h3>
                    <p>
                      Welcome to Our Warm and Inclusive Community – Join Our Big
                      Family Today!
                    </p>
                  </div>
                </div>
              </div>

              <div className="btns-box">
                <Link
                  className="btn btn-lg et-btn"
                  style={{ fontSize: "50px", padding: "50px" }}
                  to="/about"
                >
                  Discover More About Us&nbsp;
                  <i className="fa-solid fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
