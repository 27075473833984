import "../App.css";
import { Helmet } from "react-helmet";
import ServicesSidebar from "./ServicesSidebar";
import FAQ from "./FAQ";

import sienna5 from "../assets/images/sienna5.jpg";
import sienna_boot1 from "../assets/images/sienna_boot1.jpg";

export default function SaleRental() {
  return (
    <section className="sale-rental">
      <Helmet>
        <title>Vehicle Sale/Rental Service : eliteTrans</title>
      </Helmet>
      <div
        className="about-bg py-5"
        style={{
          backgroundImage:
            "linear-gradient(45deg, var(--et-color-1), var(--et-color-2))",
          color: "whitesmoke",
        }}
      >
        <h1 className="text-center py-5 my-5">Vehicle Sale/Rental</h1>
      </div>
      <div className="container py-5">
        <div className="row">
          <div className="col-md-9 order-md-2">
            <div className="img">
              <img
                className="mx-auto w-100 text-center img-fluid"
                alt="Service"
                src={sienna5}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-1-img1.jpg"
              />
            </div>
            <div className="mt-5">
              <h1 className="text-center">Vehicle Sale/Rental Service</h1>
              <p className="text-justify">
                Our Vehicle Sale/Rental Services offer a range of flexible
                mobility solutions tailored to meet your specific needs. Whether
                you're looking to purchase a vehicle that accommodates your
                unique mobility challenges or require a rental for short-term
                convenience, we have you covered. Our inventory includes a
                variety of vehicles designed with accessibility in mind,
                ensuring that you have the freedom to travel comfortably and
                independently. From accessible vans to adaptable vehicles, our
                options cater to different levels of mobility, providing you
                with the power to choose your ideal mode of transportation.{" "}
              </p>
              {/* <p className="text-justify">
                t has survived not only five centuries. Lorem Ipsum is simply
                dummy text of the new design printng and type setting Ipsum take
                a look at our round. When an unknown printer took a galley of
                type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting.{" "}
              </p> */}
            </div>
            <div className="row my-5">
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Help us Save a
                  Life
                </h4>
                <p>
                  Be a Lifesaver: Join or Engage in{" "}
                  <em>"Our Mission to Save Lives"</em>
                </p>
              </div>
              <div className="col-md-4 mx-auto p-4 py-5 my-3">
                <h4>
                  <i className="fas fa-arrow-right"></i>&nbsp;Join our Big
                  Family
                </h4>
                <p>
                  Welcome to Our Warm and Inclusive Community – Join Our Big
                  Family Today!
                </p>
              </div>
            </div>
            <p className="text-justify">
              Experience the freedom of mobility through our Vehicle Sale/Rental
              Services. Whether you're seeking to purchase a vehicle or need a
              rental, we're dedicated to enhancing your travel experience and
              giving you the flexibility to explore the world on your terms.
            </p>

            <div className="service-benefits my-5">
              <div className="row">
                <div className="col-md-6">
                  <h2>Service Benefits</h2>
                  <h4>Key Features of Our Vehicle Sale/Rental Services:</h4>
                  <ul className="et-option-list p-0">
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Diverse
                      Vehicle Selection
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Accessibility
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Flexible
                      Rental Terms
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Empowering
                      Independence
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Quality and
                      Safety
                    </li>
                    <li>
                      <i className="fas fa-check-circle"></i>&nbsp;Expert
                      Guidance
                    </li>
                  </ul>
                </div>
                <div className="col-md-6 text-center">
                  <img
                    className="img-fluid h-100"
                    alt="Care"
                    src={sienna_boot1}
                    // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/service-details-img2.jpg"
                  />
                </div>
              </div>
              <FAQ />
            </div>
          </div>
          <div className="col-md-3 order-md-1">
            <ServicesSidebar />
          </div>
        </div>
      </div>
    </section>
  );
}
