import React, { useState } from "react";
import "../App.css";
import { Helmet } from "react-helmet";

import eliteTrans_icon from "../assets/images/eliteTrans_icon_bg.png";
import Loading from "./Loading";
import Error from "./Error";

export default function Contact() {
  const [mapLoaded, setMapLoaded] = useState(false);
  const [mapError, setMapError] = useState(false);

  return (
    <section className="contact pt-5">
      <Helmet>
        <title>Contact : eliteTrans</title>
      </Helmet>
      <div
        className="contact-bg py-5"
        style={{
          backgroundImage:
            "linear-gradient(45deg, var(--et-color-1), var(--et-color-2))",
          color: "whitesmoke",
        }}
      >
        <h1 className="text-center py-5 my-5">Contact</h1>
      </div>
      <div className="container">
        <div className="row my-3">
          <div className="col-12 col-md-6 mb-3">
            <div className="img-text text-center">
              <div className="img" style={{ position: "relative" }}>
                <img
                  className="img-fluid"
                  src={eliteTrans_icon}
                  alt="Contact"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3 d-flex align-items-center">
            <div>
              <div className="sec-title">
                <div className="icon" style={{ fontSize: "72px" }}>
                  <span className="fas fa-heartbeat"></span>
                </div>
                <div className="sub-title">
                  <small>Contact us</small>
                </div>
                <h1>
                  Feel Free to Get in
                  <br />
                  Touch with us
                </h1>
              </div>
              <div className="inner-content py-4">
                <div className="text-justify">
                  <p>
                    eliteTrans team provides professional assistance to a
                    population of seniors and their busy adult children. We
                    assist busy executives, couples with two incomes but no free
                    time. We are also available in a hotel or luxury apartment
                    building, intermediate care, and assisted living residence
                    where our team assists guests or tenants with their every
                    request.
                  </p>
                </div>
                <div className="row mt-4">
                  <div className="col-xl-12">
                    <div className="single-text-box">
                      <h3>
                        <span className="fas fa-location-pin"></span>
                        &nbsp;Contact Address
                      </h3>
                      <p>404 Ballard drive Algonquin IL, 60102, USA.</p>
                    </div>
                  </div>
                  <div className="col-xl-12">
                    <div className="row">
                      <div className="col-xl-6">
                        <h3>
                          <span className="fas fa-phone"></span>&nbsp;Call
                        </h3>
                        <p>
                          <a className="et-btn" href="tel:+17734414682">
                            +1 (773) 441 4682
                          </a>
                        </p>
                      </div>
                      <div className="col-xl-6">
                        <h3>
                          <span className="fas fa-envelope"></span>&nbsp;Email
                        </h3>
                        <p>
                          <a
                            className="et-btn"
                            href="mailto:info@elitetrans.us"
                          >
                            info@elitetrans.us
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mt-3 map">
          {!mapLoaded && !mapError && (
            <Loading loadingMessage={"Loading map"} />
          )}
          {mapError && <Error errorMessage={"Cannot load map"} />}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957.4132352898555!2d-88.27963188793082!3d42.16284157109184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f0c37b8688ab9%3A0x390bbecca41b2bea!2s404%20Ballard%20Dr%2C%20Algonquin%2C%20IL%2060102%2C%20USA!5e0!3m2!1sen!2sng!4v1692880744177!5m2!1sen!2sng"
            // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2957.4132352898555!2d-88.27963188793082!3d42.16284157109184!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880f0c37b8688ab9%3A0x390bbecca41b2bea!2s404%20Ballard%20Dr%2C%20Algonquin%2C%20IL%2060102%2C%20USA!5e0!3m2!1sen!2sng!4v1692880744177!5m2!1sen!2sng&maptype=satellite"
            height="450"
            className="b-0 m-0 p-0 w-100"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            title="Physical Address"
            onLoad={() => setMapLoaded(true)}
            onError={() => setMapError(true)}
          ></iframe>
        </div>
      </div>
    </section>
  );
}
