import "../App.css";

import highlander1 from "../assets/images/highlander1.jpg";
import highlander2 from "../assets/images/highlander2.jpg";
import highlander3 from "../assets/images/highlander3.jpg";
import highlander4 from "../assets/images/highlander4.jpg";
import sienna1 from "../assets/images/sienna1.jpg";
import sienna2 from "../assets/images/sienna2.jpg";
import sienna3 from "../assets/images/sienna3.jpg";
import sienna4 from "../assets/images/sienna4.jpg";
import sienna5 from "../assets/images/sienna5.jpg";
import sienna6 from "../assets/images/sienna6.jpg";

export default function Jumbotron() {
  return (
    <>
      <div className="container-fluid d-flex justify-content-center align-items-center text-center p-0 mt-5">
        <div
          id="et-welcome-carousel"
          className="carousel carousle-fade slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>

            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="5"
              aria-label="Slide 6"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="6"
              aria-label="Slide 7"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="7"
              aria-label="Slide 8"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="8"
              aria-label="Slide 9"
            ></button>
            <button
              type="button"
              data-bs-target="#et-welcome-carousel"
              data-bs-slide-to="9"
              aria-label="Slide 10"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src={highlander1}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/slide-v1-1.jpg"
                className="d-block w-100"
                alt="Slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5>Ambularoty Service</h5>
                <p>
                  Discover a new level of convenience and care with our premier
                  ambulatory service
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                src={highlander2}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/slide-v1-2.jpg"
                className="d-block w-100"
                alt="Slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5>Specialized Care Service</h5>
                <p>
                  From medical appointments to everyday tasks, our experienced
                  professionals are here to provide the support you deserve.
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img
                src={highlander3}
                // src="https://fastwpdemo.com/newwp/ambons/wp-content/uploads/2022/02/slide-v1-3.jpg"
                className="d-block w-100"
                alt="Slide"
              />
              {/* <div className="carousel-caption d-none d-md-block">
                <h5>Vehicle Sale/Rental Service</h5>
                <p>
                  Unveil a world of possibilities with our vehicle sale/rental
                  service
                </p>
              </div> */}
            </div>
            <div className="carousel-item">
              <img src={highlander4} className="d-block w-100" alt="Slide" />
            </div>

            <div className="carousel-item">
              <img src={sienna1} className="d-block w-100" alt="Slide" />
            </div>
            <div className="carousel-item">
              <img src={sienna2} className="d-block w-100" alt="Slide" />
            </div>
            <div className="carousel-item">
              <img src={sienna3} className="d-block w-100" alt="Slide" />
            </div>
            <div className="carousel-item">
              <img src={sienna4} className="d-block w-100" alt="Slide" />
            </div>
            <div className="carousel-item">
              <img src={sienna5} className="d-block w-100" alt="Slide" />
            </div>
            <div className="carousel-item">
              <img src={sienna6} className="d-block w-100" alt="Slide" />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#et-welcome-carousel"
            data-bs-slide="prev"
          >
            <span
              className="fas fa-chevron-left control"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#et-welcome-carousel"
            data-bs-slide="next"
          >
            <span
              className="fas fa-chevron-right control"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </>
  );
}
