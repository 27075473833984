import "../App.css";

export default function FAQ() {
  return (
    <div className="row mt-5 p-3">
      <h1 className="text-center">Frequently Asked Questions</h1>
      <div
        className="accordion p-0"
        id="accordionExample"
        style={{ border: "2px solid var(--et-color-1)" }}
      >
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              style={{ color: "var(--et-color-1)" }}
            >
              What services does your company offer?
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                We provide a comprehensive range of services including
                ambulatory assistance, specialized care services, and vehicle
                sale/rental options to meet various mobility needs.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              style={{ color: "var(--et-color-1)" }}
            >
              What is ambulatory care?
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Ambulatory care involves non-emergency medical transportation
                and support for individuals who require assistance in moving to
                and from medical appointments, treatments, or other
                healthcare-related destinations.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              style={{ color: "var(--et-color-1)" }}
            >
              What is your company's covereage area?
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="mt-0 text-justify">
                eliteTrans is a US company based in Chicago therefore our
                primary area of coverage is across the United States of America.
                By extension, we also provide our services to other North
                American states like Alaska, and neighbouring countries like
                Canada, etc.
              </p>
              <p className="text-justify">
                Our automobile sales department equally facilitate sales and
                shipping of running and non-running vehicles to countries like
                Mexico, other nations like Egypt and western part of Africa.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
              style={{ color: "var(--et-color-1)" }}
            >
              How do I get in touch for more information or to book services?
            </button>
          </h2>
          <div
            id="collapseFour"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="mt-0 text-justify">
                We are available 24-hours a day and 7-days a week via our live
                chat on the lower right corner of this page. Engage with it and
                a staff at eliteTrans will get in touch with you.
              </p>
              <p className="text-justify">
                You could as well call us on{" "}
                <a
                  href="tel:+17734414682"
                  className="link-underline-warning link-offset-3"
                >
                  +1 (773) 441 4682
                </a>{" "}
                or email us{" "}
                <a
                  href="mailto:elitetransportandshipping@gmail.com"
                  className="link-underline-warning link-offset-3"
                >
                  elitetransportandshipping@gmail.com
                </a>{" "}
                or{" "}
                <a
                  href="mailto:info@elitetrans.us"
                  className="link-underline-warning link-offset-3"
                >
                  info@elitetrans.us
                </a>
              </p>
              <p className="text-justify">
                Our social links are also available. Feel free to engage us by
                using our social links found above and below this page.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFive"
              aria-expanded="false"
              aria-controls="collapseFive"
              style={{ color: "var(--et-color-1)" }}
            >
              What sets your specialized care services apart?
            </button>
          </h2>
          <div
            id="collapseFive"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Our specialized care services are designed to cater to specific
                medical and mobility requirements. Our dedicated professionals
                offer personalized care plans, ensuring comfort, safety, and
                well-being for individuals with unique needs.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSix"
              aria-expanded="false"
              aria-controls="collapseSix"
              style={{ color: "var(--et-color-1)" }}
            >
              Can you elaborate on your vehicle sale/rental services?
            </button>
          </h2>
          <div
            id="collapseSix"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="mt-0 text-justify">
                We offer both vehicle sale and rental options to provide
                flexible mobility solutions. Our range includes vehicles
                equipped to accommodate various levels of mobility challenges,
                ensuring independence and convenience.
              </p>
              <p className="text-justify">
                We are equally in partnership with various nonprofit
                organizations wishing to get grants to support and help seniors
                in our region to be more mobile.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseSeven"
              aria-expanded="false"
              aria-controls="collapseSeven"
              style={{ color: "var(--et-color-1)" }}
            >
              What types of vehicles are available for sale/rental?
            </button>
          </h2>
          <div
            id="collapseSeven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Our vehicle inventory includes a variety of options, from
                accessible vans to adaptable vehicles designed for different
                mobility requirements. Feel free to discuss your preferences
                with our team to find the best fit for you.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEight"
              aria-expanded="false"
              aria-controls="collapseEight"
              style={{ color: "var(--et-color-1)" }}
            >
              Do you provide transportation for non-medical purposes as well?
            </button>
          </h2>
          <div
            id="collapseEight"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Yes, our services extend beyond medical appointments. We're here
                to assist with various transportation needs, including social
                events, shopping, and more.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseNine"
              aria-expanded="false"
              aria-controls="collapseNine"
              style={{ color: "var(--et-color-1)" }}
            >
              How can I ensure the safety and quality of your services?
            </button>
          </h2>
          <div
            id="collapseNine"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Our company prioritizes safety and quality. We are fully
                licensed and adhere to rigorous safety standards. Our trained
                professionals and well-maintained vehicles are equipped to
                ensure your peace of mind.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTen"
              aria-expanded="false"
              aria-controls="collapseTen"
              style={{ color: "var(--et-color-1)" }}
            >
              Can I customize the care services based on my preferences?
            </button>
          </h2>
          <div
            id="collapseTen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Absolutely, we believe in tailoring our services to your needs.
                Our specialized care services can be customized to align with
                your preferences and requirements.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseEleven"
              aria-expanded="false"
              aria-controls="collapseEleven"
              style={{ color: "var(--et-color-1)" }}
            >
              Do you offer long-term rental options for vehicles?
            </button>
          </h2>
          <div
            id="collapseEleven"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Yes, we offer flexible rental terms, including long-term
                options. Whether you need a vehicle for a short duration or an
                extended period, we have solutions to accommodate your needs.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwelve"
              aria-expanded="false"
              aria-controls="collapseTwelve"
              style={{ color: "var(--et-color-1)" }}
            >
              What if I have specific medical equipment or mobility aids?
            </button>
          </h2>
          <div
            id="collapseTwelve"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Our professionals are experienced in working with a variety of
                medical equipment and mobility aids. Let us know about your
                requirements during booking, and we'll ensure your journey is
                comfortable and safe.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThirteen"
              aria-expanded="false"
              aria-controls="collapseThirteen"
              style={{ color: "var(--et-color-1)" }}
            >
              Are your services covered by insurance or government programs?
            </button>
          </h2>
          <div
            id="collapseThirteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                Depending on your location and circumstances, certain insurance
                plans or government programs may cover our services. We
                recommend checking with your provider for more information.
              </p>
            </div>
          </div>
        </div>

        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseFourteen"
              aria-expanded="false"
              aria-controls="collapseFourteen"
              style={{ color: "var(--et-color-1)" }}
            >
              What makes your company a reliable choice for mobility solutions?
            </button>
          </h2>
          <div
            id="collapseFourteen"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <p className="text-justify">
                With a proven track record in ambulatory care, specialized
                services, and vehicle solutions, we've established ourselves as
                a trusted and reliable partner in ensuring mobility and comfort
                for individuals with diverse needs.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
